import React, { useState, useEffect, memo } from "react";
import ShopTwoIcon from "@material-ui/icons/ShopTwo";
import ForumIcon from "@material-ui/icons/Forum";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import AssignmentIcon from "@material-ui/icons/Assignment";
import StorefrontIcon from "@material-ui/icons/Storefront";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import AddIcon from "@material-ui/icons/Add";
import EventIcon from "@material-ui/icons/Event";
import DashboardIcon from "@material-ui/icons/Dashboard";
// import ImageIcon from '@material-ui/icons/Image';
// import PanoramaIcon from '@material-ui/icons/Panorama';
import RedeemIcon from "@material-ui/icons/Redeem";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";

import Avatar from "assets/images/faces/avatar.jpg";

import * as S from "./style";
import { SideBar, Header } from "components";
import { useTranslation } from "react-i18next";
import Routes from "constants/routes";
import Kr from "assets/images/flags/KR.png";
import En from "assets/images/flags/GB.png";
import reactLocalStorage from "utils/localStorage";
interface Props {
  boardContent?: any;
  getInfoWallet?: () => void;
}

function DefaultLayout(props: Props) {
  const { boardContent } = props;
  const { t } = useTranslation("common");
  const { i18n } = useTranslation("common");
  const [open, setOpen] = useState(true);
  // const [isTarget, setTarget] = useState(true);

  const listOptions = [
    {
      src: Kr,
      name: "Korea",
      lang: "kr",
    },
    {
      src: En,
      name: "English",
      lang: "en",
    },
  ];

  const userInfo = {
    avtSrc: Avatar,
    useName: "Tania Andrew",
    subInfo: [
      {
        id: 1,
        codeName: "L",
        pageName: `${t("SIDEBAR_LOGOUT")}`,
        link: "#",
        isActive: false,
      },
    ],
  };

  const dataSidebar = [
    {
      titleMenu: `${t("DASH_BOARD_HEADING")}`,
      icon: <DashboardIcon />,
      // link: Routes.ADMIN_MEMBER_LIST + `/0`,
      link: Routes.ADMIN_DASHBOARD,
      subMenu: null,
    },
    {
      titleMenu: `${t("MEMBER_MANAGE_HEADING")}`,
      icon: <PeopleAltIcon />,
      // link: Routes.ADMIN_MEMBER_LIST + `/0`,
      link: Routes.ADMIN_MEMBER_LIST,
      subMenu: [
        {
          titleMenu: `${t("MEMBER_LIST_HEADING")}`,
          icon: <AddIcon />,
          // link: Routes.ADMIN_MEMBER_LIST + `/0`,
          link: Routes.ADMIN_MEMBER_LIST,
          subMenu: null,
        },
        {
          titleMenu: `${t("MEMBER_REMOVE_MANAGE_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_MEMBER_REMOVE_MANAGE,
          subMenu: null,
        },
      ],
    },
    {
      titleMenu: `${t("GROUP_PURCHASE_MANAGE_HEADING")}`,
      icon: <ShopTwoIcon />,
      link: Routes.ADMIN_PRODUCT_MANAGE,
      subMenu: [
        {
          titleMenu: `${t("PRODUCT_MANAGE_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_PRODUCT_MANAGE,
          subMenu: null,
        },
        {
          titleMenu: `${t("ORDER_HISTORY_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_ORDER_HISTORY,
          subMenu: null,
        },
        {
          titleMenu: `${t("WAITING_MANAGE_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_WAITING_MANAGE,
          subMenu: null,
        },
        // {
        //   titleMenu: `${t("GP_APPLY_ORDER_MANAGE_HEADING")}`,
        //   icon: <AddIcon />,
        //   link: Routes.ADMIN_GP_APPLY_ORDER_MANAGE,
        //   subMenu: null,
        // },
        {
          titleMenu: `${t("ART_OWNERSHIP_STATUS_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_ART_OWNERSHIP_STATUS,
          subMenu: null,
        },
        {
          titleMenu: `${t("SALE_MANAGEMENT_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_SALE_MANAGEMENT,
          subMenu: null,
        },
        // {
        //   titleMenu: `${t("CODE_MANAGE_HEADING")}`,
        //   icon: <AddIcon />,
        //   link: Routes.ADMIN_CODE_MANAGE,
        //   subMenu: null,
        // },
        // {
        //   titleMenu: `${t("GROUP_PURCHASE_SCHEDULE_HEADING")}`,
        //   icon: <AddIcon />,
        //   link: Routes.ADMIN_GROUP_PURCHASE_SCHEDULE,
        //   subMenu: null,
        // }
      ],
    },
    // {
    //   titleMenu: `${t("GROUP_PURCHASE_MANAGE_V2_HEADING")}`,
    //   icon: <ShopTwoIcon />,
    //   link: Routes.ADMIN_PRODUCT_MANAGE_V2,
    //   subMenu: [
    //     {
    //       titleMenu: `${t("PRODUCT_MANAGE_HEADING")}`,
    //       icon: <AddIcon />,
    //       link: Routes.ADMIN_PRODUCT_MANAGE_V2,
    //       subMenu: null,
    //     },
    //     {
    //       titleMenu: `${t("ORDER_HISTORY_HEADING")}`,
    //       icon: <AddIcon />,
    //       link: Routes.ADMIN_ORDER_HISTORY_V2,
    //       subMenu: null,
    //     },
    //     {
    //       titleMenu: `${t("WAITING_MANAGE_HEADING")}`,
    //       icon: <AddIcon />,
    //       link: Routes.ADMIN_WAITING_MANAGE_V2,
    //       subMenu: null,
    //     },
    //     {
    //       titleMenu: `${t("GP_APPLY_ORDER_MANAGE_HEADING")}`,
    //       icon: <AddIcon />,
    //       link: Routes.ADMIN_GP_APPLY_ORDER_MANAGE_V2,
    //       subMenu: null,
    //     },
    //     {
    //       titleMenu: `${t("ART_OWNERSHIP_STATUS_HEADING")}`,
    //       icon: <AddIcon />,
    //       link: Routes.ADMIN_ART_OWNERSHIP_STATUS_V2,
    //       subMenu: null,
    //     },
    //     {
    //       titleMenu: `${t("SALE_MANAGEMENT_HEADING")}`,
    //       icon: <AddIcon />,
    //       link: Routes.ADMIN_SALE_MANAGEMENT_V2,
    //       subMenu: null,
    //     },
    //   ],
    // },
    // {
    //   titleMenu: `${t("GROUP_PURCHASE_MANAGE_V3_HEADING")}`,
    //   icon: <ShopTwoIcon />,
    //   link: Routes.ADMIN_PRODUCT_MANAGE_V3,
    //   subMenu: [
    //     {
    //       titleMenu: `${t("PRODUCT_MANAGE_HEADING")}`,
    //       icon: <AddIcon />,
    //       link: Routes.ADMIN_PRODUCT_MANAGE_V3,
    //       subMenu: null,
    //     },
    //     // {
    //     //   titleMenu: `${t("GP_PRE_ORDER_MANAGE_HEADING")}`,
    //     //   icon: <AddIcon />,
    //     //   link: Routes.ADMIN_PRE_ORDER,
    //     //   subMenu: null,
    //     // },
    //     {
    //       titleMenu: `${t("GP_APPLY_ORDER_MANAGE_HEADING")}`,
    //       icon: <AddIcon />,
    //       link: Routes.ADMIN_GP_APPLY_ORDER_MANAGE_V3,
    //       subMenu: null,
    //     },
    //     {
    //       titleMenu: `${t("ART_OWNERSHIP_STATUS_HEADING")}`,
    //       icon: <AddIcon />,
    //       link: Routes.ADMIN_ART_OWNERSHIP_STATUS_V3,
    //       subMenu: null,
    //     },
    //     {
    //       titleMenu: `${t("SALE_MANAGEMENT_HEADING")}`,
    //       icon: <AddIcon />,
    //       link: Routes.ADMIN_SALE_MANAGEMENT_V3,
    //       subMenu: null,
    //     },
    //   ],
    // },
    {
      titleMenu: `${t("GROUP_PURCHASE_MANAGE_V3_HEADING")}`,
      icon: <ShopTwoIcon />,
      link: Routes.ADMIN_PRODUCT_MANAGE_V3,
      subMenu: [
        {
          titleMenu: `${t("PRODUCT_MANAGE_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_PRODUCT_MANAGE_V3,
          subMenu: null,
        },
        // {
        //   titleMenu: `${t("GP_PRE_ORDER_MANAGE_HEADING")}`,
        //   icon: <AddIcon />,
        //   link: Routes.ADMIN_PRE_ORDER,
        //   subMenu: null,
        // },
        {
          titleMenu: `${t("GP_APPLY_ORDER_MANAGE_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_GP_APPLY_ORDER_MANAGE_V3_1,
          subMenu: null,
        },
        {
          titleMenu: `${t("ART_OWNERSHIP_STATUS_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_ART_OWNERSHIP_STATUS_V3,
          subMenu: null,
        },
        {
          titleMenu: `${t("SALE_MANAGEMENT_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_SALE_MANAGEMENT_V3,
          subMenu: null,
        },
      ],
    },
    {
      titleMenu: `${t("GROUP_PURCHASE_MANAGE_V3_2_HEADING")}`,
      icon: <ShopTwoIcon />,
      link: Routes.ADMIN_PRODUCT_MANAGE_V3_2,
      subMenu: [
        {
          titleMenu: `${t("PRODUCT_MANAGE_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_PRODUCT_MANAGE_V3_2,
          subMenu: null,
        },
        // {
        //   titleMenu: `${t("GP_PRE_ORDER_MANAGE_HEADING")}`,
        //   icon: <AddIcon />,
        //   link: Routes.ADMIN_PRE_ORDER,
        //   subMenu: null,
        // },
        {
          titleMenu: `${t("GP_APPLY_ORDER_MANAGE_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_GP_APPLY_ORDER_MANAGE_V3_2,
          subMenu: null,
        },
        {
          titleMenu: `${t("ART_OWNERSHIP_STATUS_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_ART_OWNERSHIP_STATUS_V3_2,
          subMenu: null,
        },
        {
          titleMenu: `${t("SALE_MANAGEMENT_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_SALE_MANAGEMENT_V3_2,
          subMenu: null,
        },
      ],
    },
    {
      titleMenu: `${t("ART_MARKET_MANAGE_HEADING")}`,
      icon: <StorefrontIcon />,
      link: Routes.ADMIN_ART_PRODUCT_MANAGE,
      subMenu: [
        {
          titleMenu: `${t("PRODUCT_MANAGE_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_ART_PRODUCT_MANAGE,
          subMenu: null,
        },
        {
          titleMenu: `${t("ORDER_MANAGE_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_ART_ORDER_MANAGE,
          subMenu: null,
        },
        // {
        //   titleMenu: `${t("ORDER_EXPORT_HEADING")}`,
        //   icon: <AddIcon />,
        //   link: Routes.ADMIN_ORDER_EXPORT,
        //   subMenu: null,
        // },
        {
          titleMenu: `${t("RESTOCK_REQUEST_MANAGE_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_RESTOCK_REQUEST_MANAGE,
          subMenu: null,
        },
        // {
        //   titleMenu: `${t("DELIVERY_COMPANY_MANAGE_HEADING")}`,
        //   icon: <AddIcon />,
        //   link: Routes.ADMIN_DELIVERY_COMPANY_MANAGE,
        //   subMenu: null,
        // }
      ],
    },
    {
      titleMenu: `${t("MARKETING_EVENT_HEADING")}`,
      icon: <EventIcon />,
      link: Routes.ADMIN_EVENT_PRODUCT_MANAGE,
      subMenu: [
        {
          titleMenu: `${t("EVENT_PRODUCT_MANAGE_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_EVENT_PRODUCT_MANAGE,
          subMenu: null,
        },
        {
          titleMenu: `${t("EVENT_SUBSCRIBER_MANAGE_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_EVENT_SUBSCRIBER_MANAGE,
          subMenu: null,
        },
      ],
    },
    {
      titleMenu: `${t("ARTICLE_POPUP_MANAGE_HEADING")}`,
      icon: <ForumIcon />,
      link: Routes.ADMIN_GP_CALENDAR,
      subMenu: [
        // {
        //   titleMenu: `${t("ARTICLE_MANAGE_HEADING")}`,
        //   icon: <AddIcon />,
        //   link: Routes.ADMIN_ARTICLE_MANAGE,
        //   subMenu: null,
        // },
        {
          titleMenu: `${t("GP_CALENDAR_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_GP_CALENDAR,
          subMenu: null,
        },
        {
          titleMenu: `${t("POPUP_MANAGE_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_POPUP_MANAGE,
          subMenu: null,
        },
        {
          titleMenu: `${t("TOP_ARTIST_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_TOP_ARTIST,
          subMenu: null,
        },
      ],
    },
    {
      titleMenu: `${t("CUSTOMER_SERVICE_HEADING")}`,
      icon: <HeadsetMicIcon />,
      link: Routes.ADMIN_NOTICE,
      subMenu: [
        {
          titleMenu: `${t("GONGCHI_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_GONGCHI,
          subMenu: null,
        },
        {
          titleMenu: `${t("NOTICE_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_NOTICE,
          subMenu: null,
        },
        {
          titleMenu: `${t("USER_GUIDE_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_USER_GUIDE,
          subMenu: null,
        },
        {
          titleMenu: `${t("FAQ_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_FAQ,
          subMenu: null,
        },
        {
          titleMenu: `${t("INQUIRY_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_INQUIRY,
          subMenu: null,
        },
        {
          titleMenu: `${t("NEWSLETTER_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_NEWSLETTER,
          subMenu: null,
        },
        {
          titleMenu: `${t("ART_INSIGHT_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_ART_INSIGHT,
          subMenu: null,
        },
        // {
        //   titleMenu: `${t("PARTNERSHIP_INQUIRY_HEADING")}`,
        //   icon: <AddIcon />,
        //   link: Routes.ADMIN_PARTNERSHIP,
        //   subMenu: null,
        // },
        {
          titleMenu: `${t("GALLERY_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_GALLERY_MANAGE,
          subMenu: null,
        },
        {
          titleMenu: `${t("TERMS_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_TERMS,
          subMenu: null,
        },
      ],
    },
    {
      titleMenu: `${t("TEMP_HEADING")}`,
      icon: <AssignmentIcon />,
      link: Routes.ADMIN_TEMP,
      subMenu: null,
    },
    {
      titleMenu: `${t("EVENT_HEADING")}`,
      icon: <RedeemIcon />,
      link: Routes.ADMIN_EVENT,
      subMenu: [
        {
          titleMenu: `${t("PRAP_HEADING")}`,
          icon: <AddIcon />,
          link: Routes.ADMIN_EVENT,
          subMenu: null,
        },
      ],
    },
    {
      titleMenu: `${t("PUSH_NOTIFICATION_HEADING")}`,
      icon: <AccessAlarmIcon />,
      link: Routes.ADMIN_PUSH_NOTIFICATION,
      subMenu: null,
    },
    // {
    //   titleMenu: `${t("DOCUMENT_BANNER_MANAGE_HEADING")}`,
    //   icon: <AssignmentIcon />,
    //   link: Routes.ADMIN_BANNER_MANAGE,
    //   subMenu: [
    //     {
    //       titleMenu: `${t("BANNER_MANAGE_HEADING")}`,
    //       icon: <AddIcon />,
    //       link: Routes.ADMIN_BANNER_MANAGE,
    //       subMenu: null,
    //     },
    //     {
    //       titleMenu: `${t("DOCUMENT_MANAGE_HEADING")}`,
    //       icon: <AddIcon />,
    //       link: Routes.ADMIN_DOCUMENT_MANAGE,
    //       subMenu: null,
    //     }
    //   ]
    // },
    // {
    //   titleMenu: `${t("BANNER_MANAGE_HEADING")}`,
    //   icon: <PanoramaIcon />,
    //   link: Routes.ADMIN_BANNER_MANAGE,
    //   subMenu: null
    // },
  ];

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const getLangSelected = (e) => {
    i18n.changeLanguage(e.lang);
    reactLocalStorage.set("lang", JSON.stringify(e));

    // console.log("default", e)
  };

  useEffect(() => {
    let langSelected = JSON.parse(
      reactLocalStorage.get("lang", JSON.stringify(listOptions[0]))
    ).lang;
    i18n.changeLanguage(langSelected);
    // eslint-disable-next-line
  }, []);

  return (
    <S.Wrapper>
      <Header
        handleDrawerOpen={handleDrawerOpen}
        langSelected={getLangSelected}
        isOpen={open}
        listOptions={listOptions}
      />
      <S.Body>
        <SideBar
          userInfo={userInfo}
          sideBarMenu={dataSidebar}
          open={open}
          // infoWallet={infoWallet}
        />
        <main className="main-content overflow-off">
          <div className="board-content">{boardContent}</div>
        </main>
      </S.Body>
    </S.Wrapper>
  );
}

export default memo(DefaultLayout);
