import { ENV } from "configs/constraints";
export default function ApiConfig() {
  const configs = {
    development: {
      BASE_URL: `https://api.devartnguide.com/api/v1`,
      // BASE_URL: `http://192.168.1.53:3000/api/v1`,
      // BASE_URL: `https://api.artnguide.co.kr/api/v1`,
      // BASE_URL: `https://api.g-ang.com/api/v1`,
    },
    staging: {
      BASE_URL: import.meta.env.VITE_APP_URL_BE,
    },
    production: {
      BASE_URL: import.meta.env.VITE_APP_URL_BE,
    },
  };

  return configs[ENV];
}
