import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../../redux/store'

import { Whitelist, WhitelistState, ActionResponse } from 'models/whitelist'
import { getWhitelist, addWhiteList, rejectFromWhitelist, filterUser, getNewWhitelist } from 'services/whiteList/index'

const whitelistInitialState: WhitelistState = {
  isLoading: false,
  data: {
    msgCode: 'Failed',
    data: {
      whitelist: [],
      // currentPage: 1,
      // totalPages: 1,
      // limit: 0
    },
    errors: {}
  },
  msgCode: '',
  success: false
}

function startLoading(state: WhitelistState) {
  state.isLoading = true
}

function loadingFailed(state: WhitelistState | undefined, action: PayloadAction<any>) {
  state.isLoading = false
  // state.data.errors = action.payload?.error
}

function reset(state: WhitelistState) {
  state.data = null
  state.isLoading = false
}

const whitelist = createSlice({
  name: 'whitelist',
  initialState: whitelistInitialState,
  reducers: {
    begin: startLoading,
    setWhitelist(state, { payload }: PayloadAction<Whitelist>) {
      const { data } = payload
      state.data = {
        data: {
          whitelist: data['whitelist'],
          // currentPage: data.currentPage,
          // totalPages: data.totalPages,
          // limit: data.limit
        }
      }
    },
    setNewWhitelist(state, { payload }: PayloadAction<Whitelist>) {
      const { data } = payload
      state.data = {
        data: {
          whitelist: data['whitelist']['data'],
          currentPage: data.currentPage,
          limit: data.limit,
          totalPages: data.totalPages,
          count: data.count
        }
      }
    },
    filterNewUsers(state, { payload }: PayloadAction<Whitelist>) {
      const { data } = payload
      state.data = {
        data: {
          whitelist: data['whitelist']
        }
      }
    },
    addWhitelistUser(state, { payload }: PayloadAction<ActionResponse>) {
      state.msgCode = payload.msgCode
      state.success = payload.success
    },
    rejectItemFromWhitelist(state, { payload }: PayloadAction<ActionResponse>) {
      state.msgCode = payload.msgCode
      state.success = payload.success
    },
    failure: loadingFailed,
    resetState: reset
  }
})

export const {
  setWhitelist,
  setNewWhitelist,
  addWhitelistUser,
  filterNewUsers,
  rejectItemFromWhitelist,
  begin,
  failure,
  resetState
} = whitelist.actions

export const getWhitelistUser = (): AppThunk => async (dispatch) => {
  try {
    dispatch(begin())
    const response = await getWhitelist()
    dispatch(setWhitelist(response))
  } catch (error) {
    dispatch(failure(error))
  }
}

export const getNewWhitelistedUser = (params: any): AppThunk => async (dispatch) => {
  try {
    dispatch(begin())
    const response = await getNewWhitelist(params)
    dispatch(setNewWhitelist(response))
  } catch (error) {
    dispatch(failure(error))
  }
}

export const addNewWhitelistUser = (formData: any): AppThunk => async (dispatch) => {
  try {
    dispatch(begin())
    const response = await addWhiteList(formData)
    dispatch(addWhitelistUser(response))
  } catch (error) {
    dispatch(failure(error))
  }
}

export const rejectUserFromWhitelist = (email: string): AppThunk => async (dispatch) => {
  try {
    dispatch(begin())
    const response = await rejectFromWhitelist(email)
    dispatch(rejectItemFromWhitelist(response))
  } catch (error) {
    dispatch(failure(error))
  }
}

export const filterUsers = (formData: any): AppThunk => async (dispatch) => {
  try {
    dispatch(begin())
    const response = await filterUser(formData)
    dispatch(filterNewUsers(response))
  } catch (error) {
    dispatch(failure(error))
  }
}

export default whitelist.reducer