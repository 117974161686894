import React, { Suspense, lazy } from "react";
import styled from "styled-components";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import Routes from "constants/routes";
import useUser from "hooks/useUser";
import GlobalStyles from "./global-styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { LoadSpinner } from "components";
import { DefaultLayout } from "layouts";
import { RootState } from "redux/reducer";
import { useSelector } from "react-redux";

const LoginPage = lazy(() => import("pages/LoginPage"));

const DashBoard = lazy(() => import("pages/DashBoard"));

const MemberList = lazy(() => import("pages/MemberList"));
const MemberDetail = lazy(() => import("pages/MemberList/MemberDetail"));

const MemberLeave = lazy(() => import("pages/MemberLeave"));
const MemberLeaveDetail = lazy(() => import("pages/MemberLeave/MemberDetail"));

const GroupPurchaseProduct = lazy(() => import("pages/GroupPurchaseProduct"));
const ProductDetail = lazy(
  () => import("pages/GroupPurchaseProduct/ProductDetail")
);
const CreateProduct = lazy(
  () => import("pages/GroupPurchaseProduct/CreateProduct")
);

const SaleManagement = lazy(() => import("pages/SaleManagement"));
const VoteDetail = lazy(() => import("pages/VoteDetail"));
const TaxDetailFull = lazy(() => import("pages/SaleManagement/taxDetailFull"));
const TaxDetailJustBank = lazy(
  () => import("pages/SaleManagement/taxDetailJustBank")
);

const OrderHistory = lazy(() => import("pages/OrderHistory"));
const OrderDetail = lazy(() => import("pages/OrderHistory/OrderDetail"));
const ChangeOwner = lazy(() => import("pages/OrderHistory/ChangeOwner"));

const WaitingList = lazy(() => import("pages/WaitingList"));
const OrderWaitingDetail = lazy(() => import("pages/WaitingList/OrderDetail"));

const GPApplyOrder = lazy(() => import("pages/GPApplyOrder"));
const GPApplyOrderDetail = lazy(() => import("pages/GPApplyOrder/OrderDetail"));

//Start GP version2
const GroupPurchaseProductV2 = lazy(
  () => import("pages/GPV2/GroupPurchaseProduct")
);
const ProductDetailV2 = lazy(
  () => import("pages/GPV2/GroupPurchaseProduct/ProductDetail")
);
const CreateProductV2 = lazy(
  () => import("pages/GPV2/GroupPurchaseProduct/CreateProduct")
);

const SaleManagementV2 = lazy(() => import("pages/GPV2/SaleManagement"));
const TaxDetailFullV2 = lazy(
  () => import("pages/GPV2/SaleManagement/taxDetailFull")
);
const TaxDetailJustBankV2 = lazy(
  () => import("pages/GPV2/SaleManagement/taxDetailJustBank")
);

const OrderHistoryV2 = lazy(() => import("pages/GPV2/OrderHistory"));
const OrderDetailV2 = lazy(() => import("pages/GPV2/OrderHistory/OrderDetail"));
const ChangeOwnerV2 = lazy(() => import("pages/GPV2/OrderHistory/ChangeOwner"));

const WaitingListV2 = lazy(() => import("pages/GPV2/WaitingList"));
const OrderWaitingDetailV2 = lazy(
  () => import("pages/GPV2/WaitingList/OrderDetail")
);

const GPApplyOrderV2 = lazy(() => import("pages/GPV2/GPApplyOrder"));
const GPApplyOrderDetailV2 = lazy(
  () => import("pages/GPV2/GPApplyOrder/OrderDetail")
);

const ArtOwnershipStatusV2 = lazy(
  () => import("pages/GPV2/ArtOwnershipStatus")
);
const ArtOwnershipStatusDetailV2 = lazy(
  () => import("pages/GPV2/ArtOwnershipStatus/ArtOwnershipStatusDetail")
);
//End GP version2

//Start GP version3
const GroupPurchaseProductV3 = lazy(
  () => import("pages/GPV3/GroupPurchaseProduct")
);
const ProductDetailV3 = lazy(
  () => import("pages/GPV3/GroupPurchaseProduct/ProductDetail")
);
const CreateProductV3 = lazy(
  () => import("pages/GPV3/GroupPurchaseProduct/CreateProduct")
);

const SaleManagementV3 = lazy(() => import("pages/GPV3/SaleManagement"));
const TaxDetailFullV3 = lazy(
  () => import("pages/GPV3/SaleManagement/taxDetailFull")
);
const TaxDetailJustBankV3 = lazy(
  () => import("pages/GPV3/SaleManagement/taxDetailJustBank")
);

const GPApplyOrderV3 = lazy(() => import("pages/GPV3/GPApplyOrder"));
const GPApplyOrderDetailV3 = lazy(
  () => import("pages/GPV3/GPApplyOrder/OrderDetail")
);

const PreOrder = lazy(() => import("pages/GPV3/PreOrder"));
const PreOrderDetail = lazy(() => import("pages/GPV3/PreOrder/OrderDetail"));

const ArtOwnershipStatusV3 = lazy(
  () => import("pages/GPV3/ArtOwnershipStatus")
);
const ArtOwnershipStatusDetailV3 = lazy(
  () => import("pages/GPV3/ArtOwnershipStatus/ArtOwnershipStatusDetail")
);
//End GP version3

//Start GP version3_1
const GPApplyOrderV3_1 = lazy(() => import("pages/GPV3_1/GPApplyOrder"));
//End GP version3_1


//Start GP version3_2
const GroupPurchaseProductV3_2 = lazy(
  () => import("pages/GPV3_2/GroupPurchaseProduct")
);
const ProductDetailV3_2 = lazy(
  () => import("pages/GPV3_2/GroupPurchaseProduct/ProductDetail")
);
const CreateProductV3_2 = lazy(
  () => import("pages/GPV3_2/GroupPurchaseProduct/CreateProduct")
);

const SaleManagementV3_2 = lazy(() => import("pages/GPV3_2/SaleManagement"));
const TaxDetailFullV3_2 = lazy(
  () => import("pages/GPV3_2/SaleManagement/taxDetailFull")
);
const TaxDetailJustBankV3_2 = lazy(
  () => import("pages/GPV3_2/SaleManagement/taxDetailJustBank")
);

const GPApplyOrderV3_2 = lazy(() => import("pages/GPV3_2/GPApplyOrder"));
const GPApplyOrderDetailV3_2 = lazy(
  () => import("pages/GPV3_2/GPApplyOrder/OrderDetail")
);

const PreOrderV3_2 = lazy(() => import("pages/GPV3_2/PreOrder"));
const PreOrderDetailV3_2 = lazy(() => import("pages/GPV3_2/PreOrder/OrderDetail"));

const ArtOwnershipStatusV3_2 = lazy(
  () => import("pages/GPV3_2/ArtOwnershipStatus")
);
const ArtOwnershipStatusDetailV3_2 = lazy(
  () => import("pages/GPV3_2/ArtOwnershipStatus/ArtOwnershipStatusDetail")
);
//End GP version3_2

const ArtProduct = lazy(() => import("pages/ArtProduct"));
const ArtProductDetail = lazy(() => import("pages/ArtProduct/ProductDetail"));
const CreateArtProduct = lazy(() => import("pages/ArtProduct/CreateProduct"));

const ArtOrderHistory = lazy(() => import("pages/ArtOrderHistory"));
const ArtOrderDetail = lazy(() => import("pages/ArtOrderHistory/OrderDetail"));

const ArtOwnershipStatus = lazy(() => import("pages/ArtOwnershipStatus"));
const ArtOwnershipStatusDetail = lazy(
  () => import("pages/ArtOwnershipStatus/ArtOwnershipStatusDetail")
);

const CodeManage = lazy(() => import("pages/CodeManage"));
const RestockRequest = lazy(() => import("pages/RestockRequest"));
const RestockRequestDetail = lazy(
  () => import("pages/RestockRequest/RestockRequestDetail")
);

const DeliveryCompany = lazy(() => import("pages/DeliveryCompany"));
const CreateDeliveryCompany = lazy(
  () => import("pages/DeliveryCompany/CreateDeliveryCompany")
);
const DeliveryCompanyDetail = lazy(
  () => import("pages/DeliveryCompany/DeliveryCompanyDetail")
);

// const ArticleManage = lazy(() => import("pages/ArticleManage"));
// const CreateArticle = lazy(() => import("pages/ArticleManage/CreateArticle"));
// const UpdateArticle = lazy(() => import("pages/ArticleManage/UpdateArticle"));
const GroupPurchaseCalendar = lazy(() => import("pages/GroupPurchaseCalendar"));
const CreateGroupPurchaseCalendar = lazy(
  () => import("pages/GroupPurchaseCalendar/CreateGroupPurchaseCalendar")
);
const UpdateGroupPurchaseCalendar = lazy(
  () => import("pages/GroupPurchaseCalendar/UpdateGroupPurchaseCalendar")
);

const PopupManage = lazy(() => import("pages/PopupManage"));
const CreatePopup = lazy(() => import("pages/PopupManage/CreatePopup"));
const UpdatePopup = lazy(() => import("pages/PopupManage/UpdatePopup"));

const TopArtistManage = lazy(() => import("pages/TopArtist"));
// const CreateTopArtist = lazy(() => import("pages/TopArtist/CreateTopArtist"));
// const TopArtistDetail = lazy(() => import("pages/TopArtist/TopArtistDetail"));

const Notice = lazy(() => import("pages/Notice"));
const CreateNotice = lazy(() => import("pages/Notice/CreateNotice"));
const NoticeDetail = lazy(() => import("pages/Notice/NoticeDetail"));

const Gongchi = lazy(() => import("pages/Gongchi"));
const CreateGongchi = lazy(() => import("pages/Gongchi/CreateGongchi"));
const GongchiDetail = lazy(() => import("pages/Gongchi/GongchiDetail"));

const FAQ = lazy(() => import("pages/FAQ"));
const CreateFAQ = lazy(() => import("pages/FAQ/CreateFAQ"));
const FAQDetail = lazy(() => import("pages/FAQ/FAQDetail"));

const Terms = lazy(() => import("pages/Terms"));
const CreateTerms = lazy(() => import("pages/Terms/CreateTerms"));
const TermsDetail = lazy(() => import("pages/Terms/TermsDetail"));

const Newsletter = lazy(() => import("pages/Newsletter"));
const CreateNewsletter = lazy(
  () => import("pages/Newsletter/CreateNewsletter")
);
const NewsletterDetail = lazy(
  () => import("pages/Newsletter/NewsletterDetail")
);

const ArtInsight = lazy(() => import("pages/ArtInsight"));
const CreateArtInsight = lazy(
  () => import("pages/ArtInsight/CreateArtInsight")
);
const ArtInsightDetail = lazy(
  () => import("pages/ArtInsight/ArtInsightDetail")
);

const Temp = lazy(() => import("pages/Temp"));
const CreateTemp = lazy(() => import("pages/Temp/CreateTemp"));
const TempDetail = lazy(() => import("pages/Temp/TempDetail"));

const Inquiry = lazy(() => import("pages/Inquiry"));
const CreateInquiry = lazy(() => import("pages/Inquiry/CreateInquiry"));
const InquiryDetail = lazy(() => import("pages/Inquiry/InquiryDetail"));

const ListUseGuide = lazy(() => import("pages/UseGuide"));
const CreateUseGuide = lazy(() => import("pages/UseGuide/CreateUseGuide"));
const UseGuideDetail = lazy(() => import("pages/UseGuide/UseGuideDetail"));

const Partnership = lazy(() => import("pages/PartnerShip"));
const CreatePartnership = lazy(
  () => import("pages/PartnerShip/CreatePartnerShip")
);
const PartnershipDetail = lazy(
  () => import("pages/PartnerShip/PartnerShipDetail")
);

const GalleryManage = lazy(() => import("pages/GalleryManage"));

const BannerManage = lazy(() => import("pages/BannerManage"));
const CreateBanner = lazy(() => import("pages/BannerManage/CreateBanner"));
const BannerDetail = lazy(() => import("pages/BannerManage/BannerDetail"));

const Prap = lazy(() => import("pages/Prap"));

const PushNotification = lazy(() => import("pages/PushNotification"));

const EventProducts = lazy(() => import("pages/EventProducts"));
const EventProductsCreate = lazy(
  () => import("pages/EventProducts/CreateProduct")
);
const EventProductsDetail = lazy(
  () => import("pages/EventProducts/ProductDetail")
);

const EventSubscriber = lazy(() => import("pages/EventSubscriber"));
const EventSubscriberDetail = lazy(
  () => import("pages/EventSubscriber/SubscriberDetail")
);

const Errors = lazy(() => import("pages/Errors"));

const renderLoader = () => <LoadSpinner isOverlay={true} />;

const AppWrapper = styled.div`
  height: 100%;
  .ant-layout {
    height: 100%;
    .ant-layout-content {
      background-color: ${(props) => props.theme.innerContBg};
    }
  }
  .ant-layout-header {
    background: #ffffff;
    height: auto;
    padding: 0;
    line-height: inherit;
    z-index: 99;
    position: sticky;
    top: 0;
  }
`;

function PrivateRoute({ component: TargetPage, ...rest }) {
  const { isAuthenticated, userRole } = useUser();
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === true ? (
          <TargetPage {...props} />
        ) : (
          <Redirect to={Routes.LOGIN} />
        )
      }
    />
  );
}

function App() {
  const location = useLocation();
  const { isAuthenticated, userRole } = useUser();
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);

  return (
    <AppWrapper>
      {isAuthenticated === true && userRole === "ADMIN" ? (
        <DefaultLayout
          // pageHeading={t("STATISCAL_HEADING")}
          boardContent={
            <Switch>
              <Suspense fallback={renderLoader()}>
                {/* <Route path={Routes.LOGIN} component={LoginPage} /> */}
                {(location.pathname === Routes.LOGIN ||
                  location.pathname === "/") && (
                    <Redirect
                      exact
                      from={Routes.LOGIN}
                      to={Routes.ADMIN_DASHBOARD}
                    />
                  )}
                {isLoading && <LoadSpinner isOverlay={true} />}
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_DASHBOARD}
                  component={DashBoard}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_MEMBER_LIST}
                  component={MemberList}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_MEMBER_DETAIL + `/:id`}
                  component={MemberDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_MEMBER_REMOVE_MANAGE}
                  component={MemberLeave}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_MEMBER_LEAVE_DETAIL + `/:id`}
                  component={MemberLeaveDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PRODUCT_MANAGE}
                  component={GroupPurchaseProduct}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PRODUCT_DETAIL + `/:id`}
                  component={ProductDetail}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PRODUCT_CREATE}
                  component={CreateProduct}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_SALE_MANAGEMENT}
                  component={SaleManagement}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_VOTE_DETAIL + `/:id`}
                  component={VoteDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_TAX_DETAIL_FULL + `/:id`}
                  component={TaxDetailFull}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_TAX_DETAIL_JUSTBANK + `/:id`}
                  component={TaxDetailJustBank}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ORDER_HISTORY}
                  component={OrderHistory}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ORDER_HISTORY + `/:userID`}
                  component={OrderHistory}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ORDER_DETAIL + `/:id`}
                  component={OrderDetail}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_CHANGE_OWNER + `/:id`}
                  component={ChangeOwner}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_WAITING_MANAGE}
                  component={WaitingList}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_WAITING_ORDER_DETAIL + `/:id`}
                  component={OrderWaitingDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_GP_APPLY_ORDER_MANAGE}
                  component={GPApplyOrder}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_GP_APPLY_ORDER_DETAIL + `/:id`}
                  component={GPApplyOrderDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ART_OWNERSHIP_STATUS}
                  component={ArtOwnershipStatus}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ART_OWNERSHIP_STATUS_DETAIL + `/:id`}
                  component={ArtOwnershipStatusDetail}
                />

                {/* //Start GP version2 */}
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PRODUCT_MANAGE_V2}
                  component={GroupPurchaseProductV2}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PRODUCT_DETAIL_V2 + `/:id`}
                  component={ProductDetailV2}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PRODUCT_CREATE_V2}
                  component={CreateProductV2}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_SALE_MANAGEMENT_V2}
                  component={SaleManagementV2}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_TAX_DETAIL_FULL_V2 + `/:id`}
                  component={TaxDetailFullV2}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_TAX_DETAIL_JUSTBANK_V2 + `/:id`}
                  component={TaxDetailJustBankV2}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ORDER_HISTORY_V2}
                  component={OrderHistoryV2}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ORDER_HISTORY_V2 + `/:userID`}
                  component={OrderHistoryV2}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ORDER_DETAIL_V2 + `/:id`}
                  component={OrderDetailV2}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_CHANGE_OWNER_V2 + `/:id`}
                  component={ChangeOwnerV2}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_WAITING_MANAGE_V2}
                  component={WaitingListV2}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_WAITING_ORDER_DETAIL_V2 + `/:id`}
                  component={OrderWaitingDetailV2}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_GP_APPLY_ORDER_MANAGE_V2}
                  component={GPApplyOrderV2}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_GP_APPLY_ORDER_DETAIL_V2 + `/:id`}
                  component={GPApplyOrderDetailV2}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ART_OWNERSHIP_STATUS_V2}
                  component={ArtOwnershipStatusV2}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ART_OWNERSHIP_STATUS_DETAIL_V2 + `/:id`}
                  component={ArtOwnershipStatusDetailV2}
                />

                {/* //End GP version2 */}

                {/* //Start GP version3 */}
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PRODUCT_MANAGE_V3}
                  component={GroupPurchaseProductV3}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PRODUCT_DETAIL_V3 + `/:id`}
                  component={ProductDetailV3}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PRODUCT_CREATE_V3}
                  component={CreateProductV3}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_SALE_MANAGEMENT_V3}
                  component={SaleManagementV3}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_TAX_DETAIL_FULL_V3 + `/:id`}
                  component={TaxDetailFullV3}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_TAX_DETAIL_JUSTBANK_V3 + `/:id`}
                  component={TaxDetailJustBankV3}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_GP_APPLY_ORDER_MANAGE_V3}
                  component={GPApplyOrderV3}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_GP_APPLY_ORDER_DETAIL_V3 + `/:id`}
                  component={GPApplyOrderDetailV3}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PRE_ORDER}
                  component={PreOrder}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PRE_ORDER_DETAIL + `/:id`}
                  component={PreOrderDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ART_OWNERSHIP_STATUS_V3}
                  component={ArtOwnershipStatusV3}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ART_OWNERSHIP_STATUS_DETAIL_V3 + `/:id`}
                  component={ArtOwnershipStatusDetailV3}
                />

                {/* //End GP version3 */}

                {/* //Start GP version3_1 */}
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_GP_APPLY_ORDER_MANAGE_V3_1}
                  component={GPApplyOrderV3_1}
                />
                {/* //End GP version3_1 */}

                {/* //Start GP version3_2 */}
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PRODUCT_MANAGE_V3_2}
                  component={GroupPurchaseProductV3_2}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PRODUCT_DETAIL_V3_2 + `/:id`}
                  component={ProductDetailV3_2}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PRODUCT_CREATE_V3_2}
                  component={CreateProductV3_2}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_SALE_MANAGEMENT_V3_2}
                  component={SaleManagementV3_2}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_TAX_DETAIL_FULL_V3_2 + `/:id`}
                  component={TaxDetailFullV3_2}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_TAX_DETAIL_JUSTBANK_V3_2 + `/:id`}
                  component={TaxDetailJustBankV3_2}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_GP_APPLY_ORDER_MANAGE_V3_2}
                  component={GPApplyOrderV3_2}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_GP_APPLY_ORDER_DETAIL_V3_2 + `/:id`}
                  component={GPApplyOrderDetailV3_2}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PRE_ORDER_V3_2}
                  component={PreOrderV3_2}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PRE_ORDER_DETAIL_V3_2 + `/:id`}
                  component={PreOrderDetailV3_2}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ART_OWNERSHIP_STATUS_V3_2}
                  component={ArtOwnershipStatusV3_2}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ART_OWNERSHIP_STATUS_DETAIL_V3_2 + `/:id`}
                  component={ArtOwnershipStatusDetailV3_2}
                />

                {/* //End GP version3_2 */}

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ART_PRODUCT_MANAGE}
                  component={ArtProduct}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ART_PRODUCT_DETAIL + `/:id`}
                  component={ArtProductDetail}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ART_PRODUCT_CREATE}
                  component={CreateArtProduct}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ART_ORDER_MANAGE}
                  component={ArtOrderHistory}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ART_ORDER_MANAGE + `/:userID`}
                  component={ArtOrderHistory}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ART_ORDER_DETAIL + `/:id`}
                  component={ArtOrderDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_CODE_MANAGE}
                  component={CodeManage}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_RESTOCK_REQUEST_MANAGE}
                  component={RestockRequest}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_RESTOCK_REQUEST_DETAIL + `/:id`}
                  component={RestockRequestDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_DELIVERY_COMPANY_MANAGE}
                  component={DeliveryCompany}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_DELIVERY_COMPANY_CREATE}
                  component={CreateDeliveryCompany}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_DELIVERY_COMPANY_DETAIL + `/:id`}
                  component={DeliveryCompanyDetail}
                />

                {/* <PrivateRoute exact path={Routes.ADMIN_ARTICLE_MANAGE} component={ArticleManage} />
                <PrivateRoute exact path={Routes.ADMIN_CREATE_ARTICLE} component={CreateArticle} />
                <PrivateRoute exact path={Routes.ADMIN_ARTICLE_DETAIL + `/:id`} component={UpdateArticle} /> */}

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_GP_CALENDAR}
                  component={GroupPurchaseCalendar}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_CREATE_GP_CALENDAR}
                  component={CreateGroupPurchaseCalendar}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_GP_CALENDAR_DETAIL + `/:id`}
                  component={UpdateGroupPurchaseCalendar}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_POPUP_MANAGE}
                  component={PopupManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_CREATE_POPUP}
                  component={CreatePopup}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_POPUP_DETAIL + `/:id`}
                  component={UpdatePopup}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_TOP_ARTIST}
                  component={TopArtistManage}
                />
                {/* <PrivateRoute exact path={Routes.ADMIN_TOP_ARTIST_CREATE} component={CreateTopArtist} />
                <PrivateRoute exact path={Routes.ADMIN_TOP_ARTIST_DETAIL + `/:id`} component={TopArtistDetail} /> */}

                <PrivateRoute exact path={Routes.ADMIN_FAQ} component={FAQ} />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_FAQ_CREATE}
                  component={CreateFAQ}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_FAQ_DETAIL + `/:id`}
                  component={FAQDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_TERMS}
                  component={Terms}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_TERMS_CREATE}
                  component={CreateTerms}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_TERMS_DETAIL + `/:id`}
                  component={TermsDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_NEWSLETTER}
                  component={Newsletter}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_NEWSLETTER_CREATE}
                  component={CreateNewsletter}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_NEWSLETTER_DETAIL + `/:id`}
                  component={NewsletterDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ART_INSIGHT}
                  component={ArtInsight}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ART_INSIGHT_CREATE}
                  component={CreateArtInsight}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ART_INSIGHT_DETAIL + `/:id`}
                  component={ArtInsightDetail}
                />

                <PrivateRoute exact path={Routes.ADMIN_TEMP} component={Temp} />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_TEMP_CREATE}
                  component={CreateTemp}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_TEMP_DETAIL + `/:id`}
                  component={TempDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_NOTICE}
                  component={Notice}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_NOTICE_CREATE}
                  component={CreateNotice}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_NOTICE_DETAIL + `/:id`}
                  component={NoticeDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_GONGCHI}
                  component={Gongchi}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_GONGCHI_CREATE}
                  component={CreateGongchi}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_GONGCHI_DETAIL + `/:id`}
                  component={GongchiDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_INQUIRY}
                  component={Inquiry}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_INQUIRY_CREATE}
                  component={CreateInquiry}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_INQUIRY_DETAIL + `/:id`}
                  component={InquiryDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_USER_GUIDE}
                  component={ListUseGuide}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_USER_GUIDE_CREATE}
                  component={CreateUseGuide}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_USER_GUIDE_DETAIL + `/:id`}
                  component={UseGuideDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PARTNERSHIP}
                  component={Partnership}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PARTNERSHIP_CREATE}
                  component={CreatePartnership}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PARTNERSHIP_DETAIL + `/:id`}
                  component={PartnershipDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_GALLERY_MANAGE}
                  component={GalleryManage}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_BANNER_MANAGE}
                  component={BannerManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_BANNER_CREATE}
                  component={CreateBanner}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_BANNER_DETAIL + `/:id`}
                  component={BannerDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_EVENT}
                  component={Prap}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PUSH_NOTIFICATION}
                  component={PushNotification}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_EVENT_PRODUCT_MANAGE}
                  component={EventProducts}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_EVENT_PRODUCT_MANAGE_CREATE}
                  component={EventProductsCreate}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_EVENT_PRODUCT_MANAGE_DETAIL + `/:id`}
                  component={EventProductsDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_EVENT_SUBSCRIBER_MANAGE}
                  component={EventSubscriber}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_EVENT_SUBSCRIBER_MANAGE_DETAIL + `/:id`}
                  component={EventSubscriberDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ERRORS}
                  component={Errors}
                />
              </Suspense>
            </Switch>
          }
        />
      ) : (
        <Switch>
          <Suspense fallback={renderLoader()}>
            <Redirect exact from="/" to={Routes.LOGIN} />
            <Route path={Routes.LOGIN} component={LoginPage} />
          </Suspense>
        </Switch>
      )}
      <GlobalStyles />
      <CssBaseline />
    </AppWrapper>
  );
}

export default App;
