const Routes = {
  LOGIN: "/login",
  HOME: "/home",
  REGISTER: "/register",

  ADMIN_AGENCY_LIST: "/agency-list",
  ADMIN_CAR_TALK_MANAGE: "/cartalk-manage",
  ADMIN_CAR_TALK_DETAIL: "/cartalk-detail",
  ADMIN_COMMENT_MANAGE: "/comment-manage",
  ADMIN_PAYMENT_HISTORY: "/payment-history",
  ADMIN_PAYMENT_MANAGE: "/payment-manage",
  ADMIN_USAGE_MANAGE: "/usage-manage",
  ADMIN_GENERAL_ANNOUNCEMENT_MANAGE: "/general-announcement-manage",
  ADMIN_ANNOUNCEMENT_DETAIL: "/announcement-detail",
  ADMIN_CUSTOMER_MANAGE: "/customer-manage",
  ADMIN_CUSTOMER_QNA: "/customer-qna",
  ADMIN_PUSH_MANAGE: "/push-manage",

  ADMIN_ERRORS: "/errors",
  WHITE_LIST: "/white-list",
  REGISTERED: "/registered",
  REJECTED: "/rejected",
  DOWNLOAD: "/download",
  Navigation: "/navigation",

  TEST: "/test",

  //New PJ
  ADMIN_DASHBOARD: "/dashboard",

  ADMIN_MEMBER_MANAGE: "/member-manage",
  ADMIN_MEMBER_LIST: "/member-list",
  ADMIN_MEMBER_REMOVE_MANAGE: "/member-leave",
  ADMIN_MEMBER_DETAIL: "/member-detail",
  ADMIN_MEMBER_LEAVE_DETAIL: "/member-leave-detail",

  ADMIN_GROUP_PURCHASE_MANAGE: "/group-purchase-manage",
  ADMIN_SALE_MANAGEMENT: "/sale-management",
  ADMIN_VOTE_DETAIL: "/vote-detail",
  ADMIN_TAX_DETAIL_FULL: "/tax-detail-full",
  ADMIN_TAX_DETAIL_JUSTBANK: "/tax-detail-justbank",

  ADMIN_PRODUCT_MANAGE: "/product-manage",
  ADMIN_PRODUCT_DETAIL: "/product-detail",
  ADMIN_PRODUCT_CREATE: "/product-create",
  ADMIN_ORDER_HISTORY: "/order-history",
  ADMIN_ORDER_DETAIL: "/order-detail",
  ADMIN_CHANGE_OWNER: "/change-owner",
  ADMIN_WAITING_MANAGE: "/waiting-manage",
  ADMIN_WAITING_ORDER_DETAIL: "/waiting-order",
  ADMIN_GP_APPLY_ORDER_MANAGE: "/gp-apply-order",
  ADMIN_GP_APPLY_ORDER_DETAIL: "/gp-apply-order-detail",
  ADMIN_CODE_MANAGE: "/code-manage-status",
  ADMIN_GROUP_PURCHASE_SCHEDULE: "/purchase-schedule",

  ADMIN_ART_OWNERSHIP_STATUS: "/art-ownership-status",
  ADMIN_ART_OWNERSHIP_STATUS_DETAIL: "/art-ownership-status-detail",
  ADMIN_ART_OWNERSHIP_TRANSFER: "/art-ownership-transfer",

  //Start GP version2
  ADMIN_SALE_MANAGEMENT_V2: "/sale-management-v2",
  ADMIN_TAX_DETAIL_FULL_V2: "/tax-detail-full-v2",
  ADMIN_TAX_DETAIL_JUSTBANK_V2: "/tax-detail-justbank-v2",
  ADMIN_PRODUCT_MANAGE_V2: "/product-manage-v2",
  ADMIN_PRODUCT_DETAIL_V2: "/product-detail-v2",
  ADMIN_PRODUCT_CREATE_V2: "/product-create-v2",
  ADMIN_ORDER_HISTORY_V2: "/order-history-v2",
  ADMIN_ORDER_DETAIL_V2: "/order-detail-v2",
  ADMIN_CHANGE_OWNER_V2: "/change-owner-v2",
  ADMIN_WAITING_MANAGE_V2: "/waiting-manage-v2",
  ADMIN_WAITING_ORDER_DETAIL_V2: "/waiting-order-v2",
  ADMIN_GP_APPLY_ORDER_MANAGE_V2: "/gp-apply-order-v2",
  ADMIN_GP_APPLY_ORDER_DETAIL_V2: "/gp-apply-order-detail-v2",

  ADMIN_ART_OWNERSHIP_STATUS_V2: "/art-ownership-status-v2",
  ADMIN_ART_OWNERSHIP_STATUS_DETAIL_V2: "/art-ownership-status-detail-v2",
  ADMIN_ART_OWNERSHIP_TRANSFER_V2: "/art-ownership-transfer-v2",
  //End GP version2

  //Start GP version3
  ADMIN_SALE_MANAGEMENT_V3: "/sale-management-v3",
  ADMIN_TAX_DETAIL_FULL_V3: "/tax-detail-full-v3",
  ADMIN_TAX_DETAIL_JUSTBANK_V3: "/tax-detail-justbank-v3",
  ADMIN_PRODUCT_MANAGE_V3: "/product-manage-v3",
  ADMIN_PRODUCT_DETAIL_V3: "/product-detail-v3",
  ADMIN_PRODUCT_CREATE_V3: "/product-create-v3",
  ADMIN_GP_APPLY_ORDER_MANAGE_V3: "/gp-apply-order-v3",
  ADMIN_GP_APPLY_ORDER_DETAIL_V3: "/gp-apply-order-detail-v3",

  ADMIN_ART_OWNERSHIP_STATUS_V3: "/art-ownership-status-v3",
  ADMIN_ART_OWNERSHIP_STATUS_DETAIL_V3: "/art-ownership-status-detail-v3",
  ADMIN_ART_OWNERSHIP_TRANSFER_V3: "/art-ownership-transfer-v3",

  ADMIN_PRE_ORDER: "/pre-order",
  ADMIN_PRE_ORDER_DETAIL: "/pre-order-detail",
  //End GP version3

  //Start GP version3_1
  ADMIN_GP_APPLY_ORDER_MANAGE_V3_1: "/gp-apply-order-v3-1",
  //End GP version3_1

  //Start GP version3_2
  ADMIN_SALE_MANAGEMENT_V3_2: "/sale-management-v3-2",
  ADMIN_TAX_DETAIL_FULL_V3_2: "/tax-detail-full-v3-2",
  ADMIN_TAX_DETAIL_JUSTBANK_V3_2: "/tax-detail-justbank-v3-2",
  ADMIN_PRODUCT_MANAGE_V3_2: "/product-manage-v3-2",
  ADMIN_PRODUCT_DETAIL_V3_2: "/product-detail-v3-2",
  ADMIN_PRODUCT_CREATE_V3_2: "/product-create-v3-2",
  ADMIN_GP_APPLY_ORDER_MANAGE_V3_2: "/gp-apply-order-v3-2",
  ADMIN_GP_APPLY_ORDER_DETAIL_V3_2: "/gp-apply-order-detail-v3-2",

  ADMIN_ART_OWNERSHIP_STATUS_V3_2: "/art-ownership-status-v3-2",
  ADMIN_ART_OWNERSHIP_STATUS_DETAIL_V3_2: "/art-ownership-status-detail-v3-2",
  ADMIN_ART_OWNERSHIP_TRANSFER_V3_2: "/art-ownership-transfer-v3-2",

  ADMIN_PRE_ORDER_V3_2: "/pre-order-v3-2",
  ADMIN_PRE_ORDER_DETAIL_V3_2: "/pre-order-detail-v3-2",
  //End GP version3_2

  ADMIN_ART_MARKET_MANAGE: "/product-manage",
  ADMIN_ART_PRODUCT_MANAGE: "/art-product-manage",
  ADMIN_ART_PRODUCT_CREATE: "/art-product-create",
  ADMIN_ART_PRODUCT_DETAIL: "/art-product-detail",
  ADMIN_ART_ORDER_MANAGE: "/art-order-manage",
  ADMIN_ART_ORDER_DETAIL: "/art-order-detail",
  ADMIN_ORDER_EXPORT: "/order-export",
  ADMIN_RESTOCK_REQUEST_MANAGE: "/restock-request-manage",
  ADMIN_RESTOCK_REQUEST_DETAIL: "/restock-request-detail",
  ADMIN_DELIVERY_COMPANY_MANAGE: "/delivery-company-manage",
  ADMIN_DELIVERY_COMPANY_CREATE: "/delivery-company-create",
  ADMIN_DELIVERY_COMPANY_DETAIL: "/delivery-company-detail",

  ADMIN_ARTICLE_POPUP_MANAGE: "/article-popup-manage",
  ADMIN_ARTICLE_MANAGE: "/article-manage",
  ADMIN_CREATE_ARTICLE: "/article-create",
  ADMIN_ARTICLE_DETAIL: "/article-detail",

  ADMIN_GP_CALENDAR: "/gp-calendar",
  ADMIN_CREATE_GP_CALENDAR: "/gp-calendar-create",
  ADMIN_GP_CALENDAR_DETAIL: "/gp-calendar-detail",

  ADMIN_POPUP_MANAGE: "/popup-manage",
  ADMIN_CREATE_POPUP: "/popup-create",
  ADMIN_POPUP_DETAIL: "/popup-detail",

  ADMIN_TOP_ARTIST: "/top-artist-manage",
  ADMIN_TOP_ARTIST_CREATE: "/top-artist-create",
  ADMIN_TOP_ARTIST_DETAIL: "/top-artist-detail",

  ADMIN_CUSTOMER_SERVICE: "/customer-service",

  ADMIN_TERMS: "/terms",
  ADMIN_TERMS_DETAIL: "/terms-detail",
  ADMIN_TERMS_CREATE: "/terms-create",

  ADMIN_GONGCHI: "/gongchi",
  ADMIN_GONGCHI_DETAIL: "/gongchi-detail",
  ADMIN_GONGCHI_CREATE: "/gongchi-create",

  ADMIN_NOTICE: "/notice",
  ADMIN_NOTICE_DETAIL: "/notice-detail",
  ADMIN_NOTICE_CREATE: "/notice-create",

  ADMIN_USER_GUIDE: "/use-guide",
  ADMIN_USER_GUIDE_CREATE: "/use-guide-create",
  ADMIN_USER_GUIDE_DETAIL: "/use-guide-detail",

  ADMIN_FAQ: "/faq",
  ADMIN_FAQ_DETAIL: "/faq-detail",
  ADMIN_FAQ_CREATE: "/faq-create",

  ADMIN_INQUIRY: "/inquiry",
  ADMIN_INQUIRY_DETAIL: "/inquiry-detail",
  ADMIN_INQUIRY_CREATE: "/inquiry-create",

  ADMIN_NEWSLETTER: "/newsletter",
  ADMIN_NEWSLETTER_DETAIL: "/newsletter-detail",
  ADMIN_NEWSLETTER_CREATE: "/newsletter-create",

  ADMIN_ART_INSIGHT: "/art-insight",
  ADMIN_ART_INSIGHT_DETAIL: "/art-insight-detail",
  ADMIN_ART_INSIGHT_CREATE: "/art-insight-create",

  ADMIN_PARTNERSHIP: "/partnership",
  ADMIN_PARTNERSHIP_DETAIL: "/partnership-detail",
  ADMIN_PARTNERSHIP_CREATE: "/partnership-create",

  ADMIN_GALLERY_MANAGE: "/gallery",

  ADMIN_DOCUMENT_BANNER_MANAGE: "/documnet-banner-manage",
  ADMIN_BANNER_MANAGE: "/banner-manage",
  ADMIN_BANNER_CREATE: "/banner-create",
  ADMIN_BANNER_DETAIL: "/banner-detail",

  ADMIN_DOCUMENT_MANAGE: "/documnet-manage",

  ADMIN_TEMP: "/temp",
  ADMIN_TEMP_DETAIL: "/temp-detail",
  ADMIN_TEMP_CREATE: "/temp-create",

  ADMIN_EVENT: "/event",

  ADMIN_PUSH_NOTIFICATION: "/push-notification",

  ADMIN_MARKETING_EVENT: "/event-marketing",

  ADMIN_EVENT_PRODUCT_MANAGE: "/event-product",
  ADMIN_EVENT_PRODUCT_MANAGE_CREATE: "/event-product-create",
  ADMIN_EVENT_PRODUCT_MANAGE_DETAIL: "/event-product-detail",

  ADMIN_EVENT_SUBSCRIBER_MANAGE: "/event-subcriber",
  ADMIN_EVENT_SUBSCRIBER_MANAGE_DETAIL: "/event-subcriber-detail",
};

export default Routes;
