import { useSelector } from "react-redux";
import { RootState } from "redux/reducer";
function useUser() {
  const {
    // userid,
    msgCode,
    accessToken,
    // email,
    isLoading,
    error,
    authorName,
    userRole,
  } = useSelector((state: RootState) => state.user);

  return {
    isAuthenticated: !!accessToken,
    // userid,
    msgCode,
    accessToken,
    // email,
    isLoading,
    error,
    authorName,
    userRole,
  };
}

export default useUser;
