import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
height: 100%;
flex-direction: column;
overflow: hidden;
.board-content{
  /* padding: 50px 30px; */
  /* background-color: #ffffff;
  border-radius: 10px;
  margin: 70px 30px 50px;
  height: 100%;
  border: 1px solid rgba(0,0,0,0.12); */
}

.main-content{
  transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  flex-grow: 1;
  position: relative;
  overflow: auto;
  margin-bottom: 50px;
}

`

export const Body = styled.div`
  position: relative;
  display: flex;
  margin-top: 60px;
  height: 100%;
`