import React, { useState, Fragment } from "react";
// import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import "react-perfect-scrollbar/dist/css/styles.css";

import Routes from "constants/routes";
import { logOut } from "features/user/userSlice";
import reactLocalStorage from "utils/localStorage";

import { Drawer, List, ListItemText } from "@material-ui/core";
import { ListItems } from "components";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// import { useTranslation } from "react-i18next";
// import { getMe } from "services/User/index";
import * as S from "./style";
import { store } from "redux/store";

interface Props {
  userInfo?: any;
  sideBarMenu?: any;
  open?: boolean;
  infoWallet?: string;
}

// interface userTypeProps {
//   displayName?: string;
// }

function SideBar(props: Props) {
  const { userInfo, sideBarMenu, open } = props;
  // const { t } = useTranslation("common");

  const dispatch = useDispatch();
  const history = createBrowserHistory();
  const location = useLocation();
  const [userInfoAvtive, setUserInfoAvtive] = useState(false);
  // const [userInfoData, setUserInfoData] = useState<userTypeProps>();
  const { authorName } = store.getState().user;

  // useEffect(() => {
  //   getUserInfo()
  // }, [])

  // const getUserInfo = async () => {
  //   let resp = await getMe()
  //   console.log("getMe", resp)
  //   setUserInfoData(resp?.data || {})
  // }

  const handleActiveUserInfo = () => {
    setUserInfoAvtive(!userInfoAvtive);
  };

  const onLogOut = () => {
    dispatch(logOut());
    reactLocalStorage.clear();
    history.push(Routes.LOGIN);
    window.location.reload();
  };

  const selected = location.pathname;
  // console.log("location.pathname", location.pathname)

  return (
    <S.Sidebar>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        className={`sidebar ${open === true ? "open" : "close"}`}
      >
        <div className="sidebar-content">
          <div className="sidebar-scroll">
            {/* <PerfectScrollbar> */}

            <div className="user-info">
              <Link
                to="#"
                className="user-info-top"
                onClick={() => handleActiveUserInfo()}
              >
                <img className="avatar" src={userInfo.avtSrc} alt="avatar" />
                <div className="user-info-detail hiden-on-close">
                  <p className="user-info-name">
                    Welcome
                    {/* <br/><b>{userInfoData?.displayName}</b> */}
                  </p>
                  <p className="user-info-role">{authorName || ""}</p>
                </div>
              </Link>
            </div>

            <List className="menu-list">
              {/* <h4 className="title-list">Quản lý hội viên</h4> */}
              {sideBarMenu &&
                sideBarMenu.map((item?: any, index?: number) => {
                  return (
                    <Fragment key={index}>
                      <ListItems selected={selected} listData={item} />
                    </Fragment>
                  );
                })}
            </List>

            <List className="menu-list">
              {userInfo.subInfo.map((item, index) => {
                return (
                  <Link
                    key={index}
                    to={item.link}
                    className="sub-info"
                    onClick={onLogOut}
                  >
                    <span className="code-name">
                      {/* {item.codeName} */}
                      <ExitToAppIcon />
                    </span>
                    <ListItemText className="hiden-on-close sub-info-item">
                      {item.pageName}
                    </ListItemText>
                  </Link>
                );
              })}
            </List>
            {/* </PerfectScrollbar> */}
          </div>
        </div>
      </Drawer>
    </S.Sidebar>
  );
}

export default SideBar;
