import React, { useState, useEffect, memo } from "react";
// import { useDispatch } from 'react-redux'
// import { createBrowserHistory } from 'history'

import { AppBar, Toolbar, Button, IconButton } from "@material-ui/core";

import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// import PersonIcon from '@material-ui/icons/Person';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import CloseIcon from "@material-ui/icons/Close";
import HomeIcon from "@material-ui/icons/Home";

// import Routes from 'constants/routes';
// import { logOut } from 'features/user/userSlice';
import reactLocalStorage from "utils/localStorage";

// import {downloadExcel} from 'services/DownloadExcel';
// import fileDownload from 'js-file-download';
import * as S from "./style";

import Logo from "assets/images/logo.png";
import { homeUrl } from "configs/homeUrlConfig";

interface Props {
  handleDrawerOpen?: () => void;
  langSelected?: (item: any) => void;
  isOpen?: boolean;
  listOptions?: any;
}

function Header(props: Props) {
  const { handleDrawerOpen, isOpen, listOptions, langSelected } = props;
  // const dispatch = useDispatch()
  // const history = createBrowserHistory()
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [langActive, setLangActive] = useState(listOptions[0]);

  // const onLogOut = () => {
  //   dispatch(logOut())
  //   reactLocalStorage.clear()
  //   history.push(Routes.LOGIN)
  //   window.location.reload()
  // }

  const onHandleClick = (item) => {
    setLangActive(item);
    // i18n.changeLanguage(item.lang)
    langSelected(item);
    setActiveDropdown(false);
  };

  useEffect(() => {
    let lang = JSON.parse(
      reactLocalStorage.get("lang", JSON.stringify(langActive))
    );
    // console.log("setLangActive", lang)
    setLangActive(lang);
    // eslint-disable-next-line
  }, []);

  // const download = async () => {
  //   let resp = await downloadExcel();
  //   console.log("resp:",resp);
  //   const blobData = new Blob([resp?.data],{type: resp.headers['content-type']});
  //   const fileName = resp?.headers['content-disposition']?.split('filename=')[1]?.replaceAll("\"","") ?? "abcxyz.xlsx";
  //   console.log("blobData:",blobData,"fileName:",fileName);
  //   fileDownload(blobData, fileName)
  // }

  return (
    <S.Header>
      <AppBar position="fixed" className="app-bar">
        <Toolbar className="navbar-dashboard">
          <div className="left-navbar">
            <IconButton
              color="default"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={`btn-action-sidebar ${
                isOpen === true ? "btn-open" : "btn-close"
              }`}
            >
              {isOpen === true ? (
                <FormatListBulletedIcon className="arrow" />
              ) : (
                <MoreVertIcon className="arrow" />
              )}
            </IconButton>
            <div className="logo">
              <img src={Logo} alt="logo" />
            </div>
          </div>

          <div className="right-navbar">
            <Button
              variant="contained"
              color="primary"
              className="icon-heading"
            >
              <a href={homeUrl} target="_blank" rel="noreferrer">
                <HomeIcon />
              </a>
              {/* <a href="https://artnguide.co.kr" target="_blank" rel="noreferrer">
                  <HomeIcon/>
                </a> */}
            </Button>
            {/* <Button
                variant="contained"
                color="primary"
                className="icon-heading"
                onClick={download}
              >
                <NotificationsIcon/>
              </Button> */}
            {/* <Button
                variant="contained"
                color="primary"
                className="icon-heading"
                onClick={onLogOut}
              >
                <PersonIcon/>
              </Button> */}
            <S.Languages>
              <div className="language">
                <div
                  className="language-block"
                  onClick={() => setActiveDropdown(!activeDropdown)}
                >
                  {/* <span className="language-title">Languages:</span> */}
                  <img src={langActive.src} alt="language" />
                </div>
                {activeDropdown ? (
                  <ul className="pop-content">
                    <li
                      className="close-btn"
                      onClick={() => setActiveDropdown(!activeDropdown)}
                    >
                      <CloseIcon />
                    </li>

                    {listOptions.map((item, index) => {
                      return (
                        <li key={index}>
                          <button onClick={() => onHandleClick(item)}>
                            <img src={item.src} alt={item.name} />
                            <span>{item.name}</span>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </S.Languages>
          </div>
        </Toolbar>
      </AppBar>
    </S.Header>
  );
}

export default memo(Header);
