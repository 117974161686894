import React, {useState} from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

// import * as S from './style';

interface Props {
  listData?: any;
  selected?: any;
}

function Listitems({
  listData,
  selected
}: Props): React.ReactElement {
  // const [subSelected, setSubSelected] = useState("");
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
    // setSubSelected(location.pathname)
  };

  const subSelected = location.pathname

  // useEffect(() => {
  //   setSubSelected(location.pathname)
  // }, [])

  return (
    <ListItem
      button
      className={`${
        selected === listData.link ? "active" : "unActive"
      } list-item`}
    >
      {listData.subMenu !== null ? (
        <Link to={listData.link} className="navigate-menu" onClick={handleClick}>
          <ListItemIcon>{listData.icon}</ListItemIcon>
          <ListItemText className="hiden-on-close">
            {listData.titleMenu}
          </ListItemText>
          {open ? (
            <KeyboardArrowDownIcon className="hiden-on-close arrow" />
          ) : (
            <NavigateNextIcon className="hiden-on-close arrow" />
          )}
        </Link>
      ) : (
        <Link to={listData.link} className="navigate-menu">
          <ListItemIcon>{listData.icon}</ListItemIcon>
          <ListItemText className="hiden-on-close">
            {listData.titleMenu}
          </ListItemText>
        </Link>
      )}
      <div className="list-subMenu list-on-close" style={{padding: "0 15px"}}>
        {
        // listData.isActive &&
          listData.subMenu &&
          <Collapse in={open} timeout="auto" unmountOnExit>
        { listData.subMenu.map((submenuItem, i) => {
            return (
              <ListItem
                button
                className={`${
                  subSelected === submenuItem.link ? "active" : "unActive"
                } list-item`}
                key={i}
              >
              <Link
                to={submenuItem.link}
                className="sub-info navigate-menu"
              >
                <ListItemIcon>{submenuItem.icon}</ListItemIcon>
                <ListItemText className="hiden-on-close sub-info-item">
                  {submenuItem.titleMenu}
                </ListItemText>
              </Link>
            </ListItem>
            );
          })}
          </Collapse>
          }
      </div>
    </ListItem>
  )
}

export default Listitems;