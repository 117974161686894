import { combineReducers, Reducer, AnyAction } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import userReducer from "features/user/userSlice";
import newUsersReducer from "features/newUsers/newUsersSlice";
import whitelistReducer from "features/whitelist/whiteListSlice";
import loadingReducer from "features/loading/loadingSlice";
import history from "utils/history";

const combinedReducer = combineReducers({
  router: connectRouter(history),
  user: userReducer,
  newUsers: newUsersReducer,
  whitelist: whitelistReducer,
  loading: loadingReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "logOut") {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};
export default rootReducer;
